import { TypePolicies } from '@apollo/client'
import { offsetLimitPagination } from '../helpers/pagination'

export const document = (): TypePolicies => {
  return {
    NamespaceDocboxQuery: {
      merge: true,
      fields: {
        get: offsetLimitPagination({
          useOffsetLimitRead: true,
          field: 'documents',
          keyArgs: ['input', ['filter']],
        }),
      },
    },
  }
}
